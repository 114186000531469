import React from 'react';
import { Carousel, mobileSwiperParams as originalMobileSwiperParams, CarouselProps } from '../../../components/Carousel';
import { BecomeAWalkerCarouselItem, CarouselItem } from './BecomeAWalkerCarouselItem';
import { theme } from '../../../theme/theme';
import { becomeAWalkerWalkers } from '../../../data/become-a-walker-walkers';
import { pxToNumber } from '../../../wag-react/common/utils/pxToNumber';
import { Box } from '../../../wag-react/next-components/box';
const mobileSwiperParams = {
  ...originalMobileSwiperParams,
  width: 333,
  spaceBetween: 20,
  slidesPerView: 1
};
const desktopSwiperParams = {
  ...originalMobileSwiperParams,
  width: 427,
  spaceBetween: 150,
  breakpoints: {
    // iPad
    [pxToNumber(theme.breakpoints[0])]: {
      slidesOffsetBefore: 16
    },
    [pxToNumber(theme.breakpoints[1])]: {
      slidesOffsetBefore: 120
    }
  }
};
export type BecomeAWalkerCarouselProps = CarouselProps;
export const BecomeAWalkerCarousel = (props: BecomeAWalkerCarouselProps) => <Carousel navigation={false} value={becomeAWalkerWalkers} keyExtractor={(item: CarouselItem) => item.name} swiperParams={[mobileSwiperParams, desktopSwiperParams]} {...props} data-sentry-element="Carousel" data-sentry-component="BecomeAWalkerCarousel" data-sentry-source-file="BecomeAWalkerCarousel.tsx">
    {(item: CarouselItem) => <Box height="300px">
        <BecomeAWalkerCarouselItem {...item} />
      </Box>}
  </Carousel>;