import React from 'react';
import { Typography } from '../../../wag-react/components/Typography';
import { Box, BoxProps } from '../../../wag-react/next-components/box';
export type BecomeAWalkerTestimonialProps = BoxProps & Record<'title' | 'subtitle' | 'testimonial', string> & Partial<Record<'titleProps' | 'subtitleProps' | 'testimonialProps', BoxProps>>;
export const BecomeAWalkerTestimonial = ({
  title,
  titleProps,
  subtitle,
  subtitleProps,
  testimonial,
  testimonialProps,
  ...rest
}: BecomeAWalkerTestimonialProps) => <Box display={['grid', null, null, 'block']} alignItems={['center', null, 'flex-start', 'center']} justifyItems="center" textAlign={['center', null, 'left', 'center']} gridTemplateColumns={['1fr', null, '1fr 1fr', '1fr']} gridGap={['16px']} {...rest} data-sentry-element="Box" data-sentry-component="BecomeAWalkerTestimonial" data-sentry-source-file="BecomeAWalkerTestimonial.tsx">
    <Box data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerTestimonial.tsx">
      <Typography color="#FBECB9" fontSize={['48px', '56px']} fontStyle="italic" mb={0} mt={['48px', null, 0]} textAlign={['unset', null, 'right', 'unset']} {...titleProps} data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerTestimonial.tsx">
        {title}
      </Typography>
      <Typography color="#FBECB9" fontSize={['16px', '18px']} fontStyle="italic" m={0} textAlign={['unset', null, 'right', 'unset']} {...subtitleProps} data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerTestimonial.tsx">
        {subtitle}
      </Typography>
    </Box>

    <Typography fontSize={['16px', '18px']} color="white" width="310px" mb={0} mt={[0, null, '16px']} mx={['auto']} {...testimonialProps} data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerTestimonial.tsx">
      {testimonial}
    </Typography>
  </Box>;