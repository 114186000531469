import React from 'react';
import { HowItWorksSection, HowItWorksSectionItemProp } from '../../../components/HowItWorksSection';
import illustrationFlexibility from '../../../assets/illustrations/become-a-walker-flexibility.svg';
import illustrationTechnology from '../../../assets/illustrations/become-a-walker-technology.svg';
const HOW_IT_WORKS_ITEMS: HowItWorksSectionItemProp = [{
  description: "Every Pet Caregiver on Wag! must pass an extensive background check so no matter who you choose, you're always getting the pick of the litter.",
  title: 'Vetted Pet Caregivers',
  illustration: illustrationFlexibility
}, {
  description: "GPS tracking, lock boxes, and live chat with your Pet Caregivers are just some of the paw-some features to give you peace of mind during your pet's service.",
  illustration: illustrationTechnology,
  title: 'Peace of Mind'
}, {
  description: "As part of our commitment to safety, Wag!'s customer support is available 7 days a week and is easily accessible through the Wag! app.",
  illustration: illustrationTechnology,
  title: 'Support 7 days a week'
}];
export const BecomeAWalkerHowItWorksSection = () => <HowItWorksSection headingText="Why Wag!?" items={HOW_IT_WORKS_ITEMS} pt={['60px', '450px', '80px']} data-sentry-element="HowItWorksSection" data-sentry-component="BecomeAWalkerHowItWorksSection" data-sentry-source-file="BecomeAWalkerHowItWorksSection.tsx" />;