'use client';

import React, { useState } from 'react';
import ReactImageCarousel, { CommonProps, Modal, ModalGateway } from 'react-images';
import { PlayIcon } from '../../../icons/PlayIcon';
import quoteBackground from '../../../assets/images/become-a-walker-review-quote-background.svg';
import { LazyImage } from '../../../wag-react/components/lazyload/LazyImage';
import { Box } from '../../../wag-react/next-components/box';
import { Grid } from '../../../wag-react/core-components/grid';
import { Typography } from '../../../wag-react/components/Typography';
import { Flex } from '../../../wag-react/next-components/Flex';
import { Button } from '../../../wag-react/next-components/button';
import { IFrameVideoLightboxView } from '../../../wag-react/next-components/video-lightbox';
export type CarouselItem = Record<'review' | 'imageSrc' | 'youtubeEmbedSrc' | 'name' | 'city', string>;
export type BecomeAWalkerCarouselItemProps = CarouselItem;
export const BecomeAWalkerCarouselItem = ({
  city,
  imageSrc,
  name,
  review,
  youtubeEmbedSrc
}: BecomeAWalkerCarouselItemProps) => {
  const [isVideoShowing, setIsVideoShowing] = useState(false);
  return <>
      <Box height="226px" width={['333px', '427px']} py="16px" backgroundImage={`url(${quoteBackground})`} backgroundRepeat="no-repeat" style={{
      backgroundPositionX: '16px',
      backgroundPositionY: '30px'
    }} data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
        <Grid gridTemplateColumns={['161px 133px', '209px 168px']} gridGap="8px" height={['208px', '197px']} width={1} borderRadius="6px" boxShadow="0 2px 17px 0 rgba(0,0,0,0.25)" p={['24px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
          <Typography fontSize={['16px', '18px']} fontStyle="italic" mt={0} data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
            {review}
          </Typography>
          <LazyImage src={imageSrc} alt={name} height={['97px', '123px']} width={['133px', '168px']} imageProps={{
          style: {
            objectFit: 'contain'
          }
        }} data-sentry-element="LazyImage" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx" />
        </Grid>
        <Flex pl={['unset', '16px']} data-sentry-element="Flex" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
          <Button mt={['-40px', '-30px']} onClick={() => setIsVideoShowing(true)} data-sentry-element="Button" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
            <PlayIcon fontSize={['48px', '60px']} data-sentry-element="PlayIcon" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx" />
          </Button>
          <Flex height="40px" overflow="hidden" data-sentry-element="Flex" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
            <Typography fontWeight="bold" mt="4px" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
              {name}
              &nbsp;
            </Typography>
            <Typography mt="4px" style={{
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }} data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
              Dog Walker in&nbsp;
              {city}
            </Typography>
          </Flex>
        </Flex>
      </Box>
      {/* @ts-expect-error - Modal error */}
      <ModalGateway data-sentry-element="ModalGateway" data-sentry-source-file="BecomeAWalkerCarouselItem.tsx">
        {isVideoShowing ? <Modal onClose={() => setIsVideoShowing(false)}>
            <ReactImageCarousel currentIndex={0} components={{
          Footer: null,
          View: IFrameVideoLightboxView as React.ComponentType<CommonProps>
        }} frameProps={{
          autoSize: 'height'
        }} views={[{
          source: youtubeEmbedSrc
        }]} />
          </Modal> : null}
      </ModalGateway>
    </>;
};