'use client';

import React, { CSSProperties } from 'react';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { decamelizeKeys } from 'humps';
import { Typography } from '../../../components/Typography';
import { PhoneTextField } from '../../../components/PhoneTextField';
import { Markdown, defaultOptions } from '../../../components/Markdown';
import { BecomeAWalkerQrCode } from './BecomeAWalkerQrCode';
import { BecomeAWalkerAppDownload } from './BecomeAWalkerAppDownload';
import { Routes } from '../../../constants/routes';
import { DataTestIds } from '../../../constants/data-testids';
import { postDogWalkerApplication, PostDogWalkerApplicationErrorResponse } from '../../../hooks/api/usePostDogWalkerApplication';
import { useSegmentIdentify } from '../../../hooks/useSegmentIdentify';
import { useSegmentTrack } from '../../../hooks/useSegmentTrack';
import { phoneSchema } from '../../../utils/validation-schemas/phone';
import { emailSchema } from '../../../utils/validation-schemas/email';
import { getFormikFieldHelper } from '../../../utils/getFormikFieldHelper';
import { tryCatch } from '../../../utils/tryCatch';
import { getUrlQSParam } from '../../../utils/getUrlQSParam';
import { SegmentIdentifySchema } from '../../../utils/validation-schemas/segment/identify';
import { generateSha256Hash } from '../../../utils/generateSha256Hash';
import { dogWalkerWalkerApplied } from '../../../data/segment/dog-walker-segment';
import { setCookie } from '../../../utils/setCookie';
import { AUTH_TOKEN_SUBDOMAIN, WagWebAppUrl } from '../../../constants';
import { Time } from '../../../constants/time';
import { CookieKey } from '../../../constants/cookie-key';
import { WagApiError } from '../../../utils/extractWagApiError';
import { walkerSignupErrorHandling } from '../../../utils/walkerSignupErrorHandling';
import { PageLoadingSpinner } from '../../../components/PageLoadingSpinner';
import { useGetAttributionFromQuery } from '../../../hooks';
import { Grid, GridProps } from '../../../wag-react/core-components/grid';
import { Box } from '../../../wag-react/next-components/box';
import { TextField } from '../../../wag-react/core-components/textfield';
import { FormControlLabel } from '../../../wag-react/next-components/label';
import { Flex } from '../../../wag-react/next-components/Flex';
import { Button } from '../../../wag-react/next-components/button';
const phoneMarkdownOptions = {
  ...defaultOptions,
  overrides: {
    ...defaultOptions.overrides,
    a: {
      ...(defaultOptions.overrides.a as Record<string, CSSProperties>),
      props: {
        ...(defaultOptions.overrides.a as Record<string, CSSProperties>).props,
        fontWeight: 'bold'
      }
    },
    span: {
      ...(defaultOptions.overrides.span as Record<string, CSSProperties>),
      props: {
        fontSize: '14px',
        style: {
          wordWrap: 'break-word'
        }
      }
    }
  }
};
const privacyPolicyMarkdownOptions = {
  ...defaultOptions,
  overrides: {
    ...defaultOptions.overrides,
    a: {
      ...(defaultOptions.overrides.a as Record<string, CSSProperties>),
      props: {
        ...(defaultOptions.overrides.a as Record<string, CSSProperties>).props,
        fontWeight: 'bold'
      }
    },
    span: {
      ...(defaultOptions.overrides.span as Record<string, CSSProperties>),
      props: {
        fontSize: '14px',
        margin: 0,
        style: {
          wordWrap: 'break-word'
        }
      }
    }
  }
};
enum FormFields {
  LegalFirstName = 'firstName',
  LegalMiddleName = 'middleName',
  LegalLastName = 'lastName',
  Email = 'email',
  ConfirmEmail = 'confirmEmail',
  Phone = 'phone',
  TermsAndConditions = 'hasAgreedToTermsAndConditions',
  USResident = 'isUSResident',
}
const schema = Yup.object().shape({
  [FormFields.LegalFirstName]: Yup.string().required().default('').label('Legal first name'),
  [FormFields.LegalMiddleName]: Yup.string().default('').label('Legal middle name'),
  [FormFields.LegalLastName]: Yup.string().required().default('').label('Legal last name'),
  [FormFields.Email]: emailSchema.label('Email'),
  [FormFields.ConfirmEmail]: Yup.string().required().default('').oneOf([Yup.ref(FormFields.Email)], 'Emails must match').label('Confirm email'),
  [FormFields.Phone]: phoneSchema.label('Phone').default(''),
  [FormFields.TermsAndConditions]: Yup.boolean().required().default(false).oneOf([true]).label('Terms and conditions'),
  [FormFields.USResident]: Yup.boolean().required().default(false).oneOf([true]).label('US Resident')
});
const createSegmentIdentifyDataFromFormValues = async (formValues: Yup.InferType<typeof schema>): Promise<SegmentIdentifySchema> => {
  const [hashEmail, err] = await tryCatch(() => generateSha256Hash(formValues[FormFields.Email]));
  const leadId = `applicant_lead_${hashEmail}`;
  if (err) {
    throw new Error('Failed to generate 256 hash for the given email');
  }
  const traits = {
    firstName: formValues[FormFields.LegalFirstName],
    lastName: formValues[FormFields.LegalLastName],
    phone: formValues[FormFields.Phone],
    email: formValues[FormFields.Email]
  };
  return {
    userId: leadId,
    traits: decamelizeKeys(traits)
  };
};
export type BecomeAWalkerHeroHeaderFormProps = GridProps;
export const BecomeAWalkerHeroHeaderForm = (props: BecomeAWalkerHeroHeaderFormProps) => {
  const router = useRouter();
  const {
    execute: getAttribution
  } = useGetAttributionFromQuery();
  const attribution = getAttribution();
  const {
    execute: executeSegmentIdentify
  } = useSegmentIdentify();
  const {
    execute: executeSegmentTrack
  } = useSegmentTrack();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    status,
    isSubmitting,
    setFieldValue
  } = useFormik({
    validationSchema: schema,
    initialValues: schema.cast({}),
    validateOnMount: true,
    onSubmit: async (formValues, {
      setSubmitting,
      setStatus
    }) => {
      window.dataLayer.push({
        event: 'submitRegistrationForm'
      });
      setSubmitting(true);
      setStatus(undefined);
      let referrerCode;
      if (getUrlQSParam('referring_walker_id')) {
        referrerCode = getUrlQSParam('referring_walker_id');
      } else if (getUrlQSParam('code')) {
        referrerCode = getUrlQSParam('code');
      }
      const source = getUrlQSParam('source') || 'website';
      const [res, err] = await tryCatch(() => postDogWalkerApplication({
        payload: {
          referrerCode,
          source,
          attribution,
          ...formValues
        }
      }));
      setSubmitting(false);
      if (err) {
        if (walkerSignupErrorHandling(err as WagApiError, router)) {
          return;
        }
        setStatus((err as PostDogWalkerApplicationErrorResponse).response.data.detail);
      }
      if (res && res.data) {
        setCookie(CookieKey.AuthToken, res.data.session?.token, Time.OneMinute, AUTH_TOKEN_SUBDOMAIN);
        setCookie(CookieKey.WalkerIdentifier, res.data.identifier, Time.OneMinute, AUTH_TOKEN_SUBDOMAIN);
        // Send Segment requests & redirect user to fountain on successful event
        const segmentIdentifyData = await createSegmentIdentifyDataFromFormValues(formValues);
        await tryCatch(() => Promise.all([executeSegmentIdentify({
          data: segmentIdentifyData
        }), executeSegmentTrack({
          data: dogWalkerWalkerApplied
        })]));
        router.push(WagWebAppUrl.WalkerSignupFlow);
      }
    }
  });
  const formikFieldHelper = getFormikFieldHelper({
    errors,
    touched
  });
  return <Box as="form" bg="#F5F5F5" width={['343px', '536px']} minHeight={['300px', null, '344px']} borderRadius="4px" boxShadow="0 2px 11px 0 rgba(71,71,71,0.47)" overflow="hidden" onSubmit={handleSubmit} {...props} data-sentry-element="Box" data-sentry-component="BecomeAWalkerHeroHeaderForm" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
      {isSubmitting && <PageLoadingSpinner />}
      <Grid px={['20px', '40px']} py={['24px', '40px']} gridGap={['8px', null, '12px']} {...props} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
        <Typography variant="body1" fontWeight="bold" my="0px" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
          Please enter your name as it appears on your ID.
        </Typography>
        <Grid gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']} gridGap="12px" minHeight={['unset', null, '204px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
          <Grid gridGap={['8px', null, '12px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
            <Grid gridTemplateColumns={['1fr']} gridGap={['8px', null, '12px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
              <TextField fullWidth name={FormFields.LegalFirstName} value={values[FormFields.LegalFirstName]} label="Legal first name" containerProps={{
              bg: 'white'
            }} inputProps={{
              width: '100%'
            }} error={formikFieldHelper(FormFields.LegalFirstName).hasError} helperText={formikFieldHelper(FormFields.LegalFirstName).errorMessage} onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.FirstName} data-sentry-element="TextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
              <TextField fullWidth name={FormFields.LegalMiddleName} value={values[FormFields.LegalMiddleName]} label="Legal middle name" containerProps={{
              bg: 'white'
            }} inputProps={{
              width: '100%'
            }} error={formikFieldHelper(FormFields.LegalMiddleName).hasError} helperText={formikFieldHelper(FormFields.LegalMiddleName).errorMessage} onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.MiddleName} data-sentry-element="TextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
              <TextField fullWidth name={FormFields.LegalLastName} value={values[FormFields.LegalLastName]} label="Legal last name" containerProps={{
              bg: 'white'
            }} inputProps={{
              width: '100%'
            }} error={formikFieldHelper(FormFields.LegalLastName).hasError} helperText={formikFieldHelper(FormFields.LegalLastName).errorMessage} onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.LastName} data-sentry-element="TextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
            </Grid>
            <TextField fullWidth name={FormFields.Email} value={values[FormFields.Email]} label="Email address" type="email" containerProps={{
            bg: 'white'
          }} error={formikFieldHelper(FormFields.Email).hasError} helperText={formikFieldHelper(FormFields.Email).errorMessage} onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.Email} data-sentry-element="TextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
            <TextField fullWidth name={FormFields.ConfirmEmail} value={values[FormFields.ConfirmEmail]} label="Confirm email address" type="email" containerProps={{
            bg: 'white'
          }} error={formikFieldHelper(FormFields.ConfirmEmail).hasError} helperText={formikFieldHelper(FormFields.ConfirmEmail).errorMessage} onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.ConfirmEmail} data-sentry-element="TextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
            <PhoneTextField name={FormFields.Phone} value={values[FormFields.Phone]} international={false} label="Phone number" variant="outlined" countrySelectProps={{
            disabled: true
          }} containerProps={{
            border: 'unset'
          }} style={{
            // This will be applied to the PhoneInput
            // not on the TextField
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: formikFieldHelper(FormFields.Phone).hasError ? 'red' : '#D1D1D1'
          }} error={formikFieldHelper(FormFields.Phone).hasError} helperText={formikFieldHelper(FormFields.Phone).errorMessage} onChange={phone => {
            setFieldValue(FormFields.Phone, phone);
          }} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.Phone} data-sentry-element="PhoneTextField" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
          </Grid>
          <BecomeAWalkerQrCode display={['none', null, 'flex']} data-sentry-element="BecomeAWalkerQrCode" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
        </Grid>
        <Markdown options={phoneMarkdownOptions} data-sentry-element="Markdown" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
          {`<span> By submitting my phone number, I agree to receive automated and non-automated transactional and marketing calls/texts from Wag!. Consent not required; text STOP to 24006 to opt out. Opt out instructions subject to change. Message and data rates may apply. [Mobile Terms](${Routes.MobileTerms}) apply.</span>`}
        </Markdown>
        <FormControlLabel display="grid" gridTemplateColumns={['24px 1fr']} alignItems="flex-start" control={<input name={FormFields.TermsAndConditions} defaultChecked={values[FormFields.TermsAndConditions]} type="checkbox" onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.TermsAndConditions} />}
      // @ts-expect-error - Typing issue
      label={<Markdown options={privacyPolicyMarkdownOptions}>
              {`<span> I agree to Wag!'s [Terms](${Routes.TermsAndConditions}) (including a [mutual arbitration clause](${Routes.MutualArbitrationClause})) and [Privacy Policy](${Routes.PrivacyPolicy}).</span>`}
            </Markdown>} data-sentry-element="FormControlLabel" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
        <FormControlLabel display="grid" gridTemplateColumns={['24px 1fr']} alignItems="flex-start" control={<input name={FormFields.USResident} defaultChecked={values[FormFields.USResident]} type="checkbox" onChange={handleChange} onBlur={handleBlur} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.USResident} />}
      // @ts-expect-error - Typing issue
      label={<Typography fontSize="14px" m={0}>
              I confirm that I am a US permanent resident and am over 18
            </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
        <Flex justifyContent="center" flexDirection="column" alignItems={['center', null, 'start']} mt="16px" data-sentry-element="Flex" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
          {status && <Typography fontSize="14px" data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.RequestError} color="red">
              {status}
            </Typography>}
          <Button type="submit" variant="contained" bg="#FAAB43" color="white" disabled={!isValid || isSubmitting} data-testid={DataTestIds.DogWalkerPage.DogWalkerHeroForm.SubmitBtn} data-sentry-element="Button" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx">
            Get Started
          </Button>
        </Flex>
      </Grid>

      {/* Mobile only */}
      <BecomeAWalkerAppDownload display={['block', null, 'none']} data-sentry-element="BecomeAWalkerAppDownload" data-sentry-source-file="BecomeAWalkerHeroHeaderForm.tsx" />
    </Box>;
};