export const becomeAWalkerTestimonials = [
  {
    title: '400K+',
    subtitle: 'Pet Caregivers',
    testimonial: 'Our community of 400,000+ Pet Caregivers nationwide are dog people, and it shows.',
  },
  {
    title: '5.3K+',
    subtitle: 'Cities across the U.S.',
    testimonial: 'Pet Caregivers with Wag! have a trusted record of experience with over 12 million pet care services, including Sitting, Walking, Boarding & Training, across 5,300+ cities in all 50 U.S. states.',
  },
  {
    title: '16M+',
    subtitle: 'Meals donated',
    testimonial: 'We’ve donated over 16 million meals! A portion of our proceeds from the walks you book help to feed shelter dogs in your area.',
  },
  {
    title: '99%',
    subtitle: '5-star reviews',
    testimonial: 'Making Pet Parents happy is what we do best — 99% of Wag! services result in a 5-star review.',
  },
];
