import React from 'react';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { LazyImage } from '../../../components/LazyImage';
import { VideoLink } from '../../../constants/app';
import wave from '../../../assets/waves/wave-grey.svg';
import partnerProgramMobileImage from '../../../assets/images/become-a-walker-partner-program-mobile.jpg';
import partnerProgramImage from '../../../assets/images/become-a-walker-partner-program.png';
import { Box } from '../../../wag-react/next-components/box';
import { Grid } from '../../../wag-react/core-components/grid';
import { Typography } from '../../../wag-react/components/Typography';
import { Button } from '../../../wag-react/next-components/button';
export const BecomeAWalkerPartnerProgramSection = () => <>
    <Box bg="backgroundGray" data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
      <Container py={['40px', '80px']} data-sentry-element="Container" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
        <Grid gridTemplateColumns={['1fr', null, '1fr 1fr']} gridGap="40px" data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
            <Typography fontSize={['24px', '48px']} fontWeight="bold" my="0" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
              Love dogs? Get paid for it!
            </Typography>
            <Typography fontSize="18px" my="16px" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
              Caring for dogs is what you do best.
              Connecting dog walkers, sitters, and trainers with
              a nationwide community of pet parents who have dog walking,
              overnight sitting & boarding, or training needs is what we do best.
              Build your business and become your own boss with Wag!.
            </Typography>
            <Box as={Button} href={VideoLink.BecomeAWalkerPartnerProgramSection} fontSize="18px" fontWeight="bold" target="_blank" data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
              Meet some Pet Caregivers who walk for Wag!
            </Box>
          </Box>
          <Box borderRadius="8px" overflow="hidden" data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx">
            <LazyImage src={[partnerProgramMobileImage, partnerProgramImage]} alt="partner-program-section-image" width={['382px', '547px']} height={['240px', '336px']} imageProps={{
            style: {
              objectFit: 'contain'
            }
          }} data-sentry-element="LazyImage" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx" />
          </Box>
        </Grid>
      </Container>
    </Box>
    <Image src={wave} alt="Grey wave" minHeight="25px" width={1} mt="-1px" data-sentry-element="Image" data-sentry-source-file="BecomeAWalkerPartnerProgramSection.tsx" />
  </>;