import React from 'react';
import { Image } from '../../../wag-react/next-components/image';
import { BackgroundImage } from '../../../components/BackgroundImage';
import { Container } from '../../../components/Container';
import { BecomeAWalkerHeroHeaderForm } from './BecomeAWalkerHeroHeaderForm';
import { ScrollableElementContainer } from '../../../components/ScrollableElementContainer';
import backgroundMobile from '../../../assets/hero-header-backgrounds/become-a-walker-background-mobile.jpg';
import backgroundDesktop from '../../../assets/hero-header-backgrounds/become-a-walker-background-desktop.jpg';
import bannerWalkers from '../../../assets/images/become-a-walker-banner-walkers.png';
import { Grid } from '../../../wag-react/core-components/grid';
import { Typography } from '../../../wag-react/components/Typography';
export const BecomeAWalkerHeroHeaderSection = () => <>
    <BackgroundImage backgroundImage={[`url(${backgroundMobile})`, null, `url(${backgroundDesktop})`]} minHeight={['1100px', '800px', '700px']} data-sentry-element="BackgroundImage" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
      <Container pt={['32px', '48px']} display="grid" gridTemplateColumns={['1fr', null, '1fr 1fr']} gridGap={['30px', null, null, '64px']} justifyItems={['center', null, 'unset']} data-sentry-element="Container" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
        <Grid flexDirection={['column', 'column']} alignItems="center" justifyItems={['center', null, 'start', 'start']} gridTemplateRows={['76px 60px 80px', '76px 90px 80px', '160px 60px 80px']} gridGap={['unset', '8px', '24px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
          <Typography color="white" fontFamily="muliBold" m={0} mx={['auto', 0]} maxWidth={['316px', '632px', '632px', '532px']} textAlign={['center', 'left']} variant="h1" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
            Become a Dog Walker with Wag!
          </Typography>
          <Typography color="white" fontWeight={3} maxWidth={['100%', '288px', '552px', '660px']} mx={['auto', 0]} m={0} textAlign={['center', 'left']} variant="h3" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
            Join our community of over 400K Pet Caregivers nationwide
          </Typography>
          <Image src={bannerWalkers} alt="banner-walkers" width={['343px', '540px']} height={['51px', '80px']} data-sentry-element="Image" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx" />
        </Grid>

        <ScrollableElementContainer id="become-a-walker-application-form" offset={100} data-sentry-element="ScrollableElementContainer" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx">
          <BecomeAWalkerHeroHeaderForm data-sentry-element="BecomeAWalkerHeroHeaderForm" data-sentry-source-file="BecomeAWalkerHeroHeaderSection.tsx" />
        </ScrollableElementContainer>
      </Container>
    </BackgroundImage>
  </>;