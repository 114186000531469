import React from 'react';
import { Typography } from '../../../components/Typography';
import { DogWalkerAppLink } from '../../../constants/app';
import { Box, BoxProps } from '../../../wag-react/next-components/box';
import Link from 'next/link';
export type BecomeAWalkerAppDownloadProps = BoxProps;
export const BecomeAWalkerAppDownload = (props: BecomeAWalkerAppDownloadProps) => <Box backgroundColor="white" px={['20px', null, '40px']} py={['24px', null, '40px']} {...props} data-sentry-element="Box" data-sentry-component="BecomeAWalkerAppDownload" data-sentry-source-file="BecomeAWalkerAppDownload.tsx">
    <Typography m={0} fontSize="14px" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerAppDownload.tsx">
      Approved Caregiver?{' '}
      <Link href={DogWalkerAppLink.SmartLink} target="_blank" data-sentry-element="Link" data-sentry-source-file="BecomeAWalkerAppDownload.tsx">
        Download the app
      </Link>
    </Typography>
  </Box>;