import React from 'react';
import { Helmet } from '../../components/Helmet';
import { Layout } from '../../components/Layout';
import { BecomeAWalkerPageContainer } from '../../containers/BecomeAWalkerPageContainer';
import { useCanonicalUrl } from '../../hooks/useCanonicalUrl';
const Index = () => {
  const {
    canonicalUrl
  } = useCanonicalUrl();
  return <>
      <Helmet canonical={canonicalUrl} description="Wag! is #1 for building your pet care business. Join our community of local dog walkers, pet sitters, and pet trainers to start making money today." title="Wag! | #1 App for Local Dog Walking & Pet Sitting Jobs Near You" robots="index, follow" data-sentry-element="Helmet" data-sentry-source-file="index.tsx" />
      <Layout data-sentry-element="Layout" data-sentry-source-file="index.tsx">
        <BecomeAWalkerPageContainer data-sentry-element="BecomeAWalkerPageContainer" data-sentry-source-file="index.tsx" />
      </Layout>
    </>;
};
export default Index;