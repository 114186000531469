import React from 'react';
import { DogWalkerAppLink } from '../../../constants/app';
import qrCode from '../../../assets/images/become-a-walker-qr-code.jpeg';
import { LazyImage } from '../../../wag-react/components/lazyload/LazyImage';
import { Flex, FlexProps } from '../../../wag-react/next-components/Flex';
import { Typography } from '../../../wag-react/components/Typography';
export type BecomeAWalkerQrCodeProps = FlexProps;
export const BecomeAWalkerQrCode = (props: BecomeAWalkerQrCodeProps) => <Flex flexDirection="column" alignItems="center" backgroundColor="white" borderRadius="8px" px={1} py="24px" maxHeight="204px" {...props} data-sentry-element="Flex" data-sentry-component="BecomeAWalkerQrCode" data-sentry-source-file="BecomeAWalkerQrCode.tsx">
    <LazyImage src={qrCode} alt={DogWalkerAppLink.AppStore} height="104px" width="104px" imageProps={{
    style: {
      wordBreak: 'break-word'
    }
  }} data-sentry-element="LazyImage" data-sentry-source-file="BecomeAWalkerQrCode.tsx" />
    <Typography fontSize="14px" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerQrCode.tsx">
      Approved Caregiver?
      {' '}
      <br />
      Download the app.
    </Typography>
  </Flex>;