import React from 'react';
import { Container } from '../../../components/Container';
import { BecomeAWalkerTestimonial } from './BecomeAWalkerTestimonial';
import { becomeAWalkerTestimonials } from '../../../data/become-a-walker-testimonials';
import { scrollToViewById } from '../../../utils/scrollToView';
import { Routes } from '../../../constants/routes';
import { Box, BoxProps } from '../../../wag-react/next-components/box';
import { Typography } from '../../../wag-react/components/Typography';
import { Grid } from '../../../wag-react/core-components/grid';
import { Button } from '../../../wag-react/next-components/button';
export type BecomeAWalkerAboutSectionProps = BoxProps;
export const BecomeAWalkerAboutSection = (props: BecomeAWalkerAboutSectionProps) => {
  const handleOnClickStartApplication = () => {
    scrollToViewById('become-a-walker-application-form');
  };
  return <Box mt="40px" {...props} data-sentry-element="Box" data-sentry-component="BecomeAWalkerAboutSection" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
      <Box backgroundColor="#3A5080" data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
        <Container py="40px" data-sentry-element="Container" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
          <Typography width={[1, null, '894px']} m="0 auto" variant="h2" fontSize={['30px', null, '48px']} color="white" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
            {'We\'ve been around the block'}
          </Typography>
          <Grid display="grid" gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gridGap={['unset', null, '72px']} my={['16px', null, '40px']} data-sentry-element="Grid" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
            {becomeAWalkerTestimonials.map((testimonial, index) => <BecomeAWalkerTestimonial key={testimonial.title} {...index === 1 && {
            gridArea: ['auto', null, '2', 'auto']
          }} {...testimonial} />)}
          </Grid>
          <Box textAlign="center" mt="40px" data-sentry-element="Box" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
            <Button variant="contained" bg="#FBECB9" color="#85623E" onClick={handleOnClickStartApplication} data-sentry-element="Button" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
              Sign up now
            </Button>
            <Typography color="White" data-sentry-element="Typography" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
              Have some questions?
              Visit our&nbsp;
              <Button href={Routes.DogWalkerFaq} color="White" fontWeight="bold" data-sentry-element="Button" data-sentry-source-file="BecomeAWalkerAboutSection.tsx">
                FAQ page
              </Button>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>;
};