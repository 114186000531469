import walkerKatie from '../assets/images/become-a-walker-walkers/walker_katie.png';
import walkerKelsey from '../assets/images/become-a-walker-walkers/walker_kelsey.png';
import walkerRachel from '../assets/images/become-a-walker-walkers/walker_rachel.png';

export const becomeAWalkerWalkers = [
  {
    review: 'I’m getting paid to hang out with dogs, explore LA, get some sunshine, get some exercise. It’s a really awesome gig!',
    imageSrc: walkerKatie,
    youtubeEmbedSrc: 'https://www.youtube.com/embed/Tx33UY5LQno',
    name: 'Katie M.',
    city: 'Los Angeles',
  },
  {
    review: 'Wag! motivates me to get out & be active. Some of my favorite NY memories are because of Wag! and my furry friends.',
    imageSrc: walkerKelsey,
    youtubeEmbedSrc: 'https://www.youtube.com/embed/RIqpal6fJaU',
    name: 'Kelsey V.',
    city: 'New York',
  },
  {
    review: 'One of the things that I love about working for Wag! is that it’s literally my job to go hang out with other people’s dogs.',
    imageSrc: walkerRachel,
    youtubeEmbedSrc: 'https://www.youtube.com/embed/-1qi6JNIh5c',
    name: 'Rachel R.',
    city: 'Wichita, Kansas',
  },
];
