import React from 'react';
import { BecomeAWalkerAboutSection } from './components/BecomeAWalkerAboutSection';
import { BecomeAWalkerHeroHeaderSection } from './components/BecomeAWalkerHeroHeaderSection';
import { BecomeAWalkerHowItWorksSection } from './components/BecomeAWalkerHowItWorksSection';
import { BecomeAWalkerPartnerProgramSection } from './components/BecomeAWalkerPartnerProgramSection';
import { BecomeAWalkerReviewsSection } from './components/BecomeAWalkerReviewsSection';
export const BecomeAWalkerPageContainer = () => <>
    <BecomeAWalkerHeroHeaderSection data-sentry-element="BecomeAWalkerHeroHeaderSection" data-sentry-source-file="index.tsx" />
    <BecomeAWalkerHowItWorksSection data-sentry-element="BecomeAWalkerHowItWorksSection" data-sentry-source-file="index.tsx" />
    <BecomeAWalkerPartnerProgramSection data-sentry-element="BecomeAWalkerPartnerProgramSection" data-sentry-source-file="index.tsx" />
    <BecomeAWalkerReviewsSection data-sentry-element="BecomeAWalkerReviewsSection" data-sentry-source-file="index.tsx" />
    <BecomeAWalkerAboutSection data-sentry-element="BecomeAWalkerAboutSection" data-sentry-source-file="index.tsx" />
  </>;